import tns from './tiny-slider';

class BaseSlide extends HTMLElement {
  constructor() {
    super();
    this.slider = null;
  }
  init() {}
  initSlide() {
    const _this = this;
    const items = this.dataset?.items;
    const itemsMb = this.dataset?.itemsMb;
    const atto = this.dataset?.autoplayTimeout;
    const cp = this.dataset?.controlsPosition;
    const np = this.dataset?.navPosition;
    const a = this.dataset?.axis;
    const m = this.dataset?.mode;
    const nc = this.dataset?.navContainer;
    const t = this.dataset?.productType;
    let sti = this.dataset?.startIndex;
    const mh = this.dataset?.maxHeight;
    const im = this.dataset?.mobile;
    const ctn = this.querySelector('.slide-container');
    const c = this.dataset?.controls !== 'false';
    const n = this.dataset?.nav !== 'false';
    const l = this.dataset?.loop !== 'false';
    const at = this.dataset?.autoplay !== 'false';
    const md = this.dataset?.mouseDrag !== 'false';
    const nat = this.dataset?.navAsThumbnails !== 'false';
    const fh = this.dataset?.fixHeight !== 'false';
    const lazyload = this.dataset?.lazyload !== 'false';
    const next = this.dataset?.next;
    const prev = this.dataset?.prev;
    const center = this.dataset?.navCenterPostion;
    const speed = this.dataset?.speed;

    if (this.closest('media-gallery')) {
      const parent = this.closest('media-gallery').closest('.product__item-js');
      if (
        parent &&
        parent.querySelector('.productJson') &&
        this.closest('media-gallery').dataset.variantId
      ) {
        let stix = JSON.parse(parent.querySelector('.productJson').textContent);
        if (parent.classList.contains('main__product')) {
          sti =
            this.getPosition(this.closest('media-gallery').dataset.variantId, stix.variants) - 1;
        }
      }
    }
    if (!ctn) return;
    this.slider = tns({
      container: ctn,
      autoplay: at,
      items: itemsMb ? itemsMb : im ? im : t ? (items > 1 ? 2 : 1) : items ? items : 1,
      responsive: {
        768: {
          items: t && items > 2 ? 2 : items ? items : 1,
        },
        992: {
          items: t && items > 3 ? 4 : items ? items : 1,
        },
        1025: {
          items: items ? items : 1,
        },
      },
      autoplayHoverPause: true,
      mouseDrag: md,
      lazyload: lazyload,
      autoplayTimeout: atto ? atto : 5000,
      controls: c,
      controlsPosition: cp ? cp : 'top',
      loop: l,
      axis: a ? a : 'horizontal',
      swipeAngle: false,
      mode: m ? m : 'carousel',
      navContainer: nc ? `#${nc}` : false,
      onInit: _this.tnsInitialized(ctn),
      startIndex: sti ? sti : 0,
      centerNav: center ? `.${center}` : false,
      nextButton: next ? `#${next}` : false,
      prevButton: prev ? `#${prev}` : false,
      navAsThumbnails: nat,
      nav: n,
      navPosition: np ? np : 'bottom',
      autoplayButtonOutput: false,
      animateOut: 'fadeOut',
      speed: speed ? speed : 300,
    });
    if (fh) {
      _this.fixHeightFunction(mh);
    }
    if (this.slider) {
      this.slider.events.on('indexChanged', function (info) {
        _this.pauseMedia(info);
      });
    }
  }
  tnsInitialized(ctn) {
    ctn.classList.add('tns-initialized');
    const is_product_slide = this.dataset.slideProduct;
    if (is_product_slide) {
      var t;
      const swatch_items = this.querySelectorAll('.swatch-items-js');
      if (swatch_items.length != 0) {
        swatch_items.forEach((e) => {
          const productTarget = e.closest('.product__item-js');
          const parent =
            e.closest('product-recommendations') ||
            e.closest('recently-viewed-products') ||
            e.closest('slide-section');
          if (productTarget) {
            e.addEventListener(
              'mouseout',
              function () {
                if (parent) {
                  t = setTimeout(() => {
                    parent.classList.remove('show-tooltip');
                  }, 400);
                }
              },
              false
            );
            e.addEventListener('mouseover', () => {
              clearTimeout(t);
              if (parent) {
                parent.classList.add('show-tooltip');
              }
            });
          }
        });
      }
    }
  }
  pauseMedia(s) {
    if (!s.container) return;
    window.pauseAllMedia(s.container);
  }
  fixHeightFunction(mh) {
    const _this = this;
    const sl = this.querySelectorAll('.tns-item');
    window.setTimeout(() => {
      _this.fixHeight(mh, sl, _this);
    }, 500);
    window.addEventListener('resize', function () {
      _this.fixHeight(mh, sl, _this);
    });
  }
  fixHeight(mh, sl, _this) {
    if (mh) {
      _this.style.height = `${mh}px`;
    } else {
      let maxh = 0;
      if (sl.length) {
        sl.forEach((s) => {
          const slh = s.offsetHeight;
          if (slh > maxh) {
            maxh = slh;
          }
        });
      }
      _this.style.height = `${maxh}px`;
    }
  }
  getPosition(variantValue, stix) {
    if (variantValue && stix) {
      var currentURL = window.location.href;
      var queryString = currentURL.split('?')[1];
      if (queryString) {
        var params = queryString.split('&');
        if (params) {
          var queryVars = {};
          params.forEach(function (param) {
            var keyValue = param.split('=');
            var key = decodeURIComponent(keyValue[0]);
            var value = decodeURIComponent(keyValue[1]);
            queryVars[key] = value;
          });
          variantValue = queryVars['variant'];
        }
      }
      var foundVariant = stix.find(function (variant) {
        return variant.id === Number(variantValue);
      });
      var position = foundVariant ? foundVariant.featured_image?.position : 0;
      return position;
    }
  }
}

export default BaseSlide;
